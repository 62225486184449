import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostTopmatter from "../components/post_topmatter"

export default ({ data, pageContext }) => {
	console.log(data)
	console.log(pageContext)
	const post = data.markdownRemark
	const frontmatter = post.frontmatter
	return (
		<Layout 
			newerPath={pageContext.newerSlug} 
			olderPath={pageContext.olderSlug}
			width={frontmatter.postWidth}>
			<div className="container">
				<div className="row">
					<div className="col d-flex justify-content-center">
						<div
							className="post--inner"
							style={{
								borderColor: frontmatter.borderColor || "rgba(247,227,227, 0.5)",
								borderWidth: frontmatter.borderWidth || "3px",
								width: frontmatter.postWidth || "720px"
							}}
						>
							<PostTopmatter
								date={frontmatter.publishDate}
								underlineColor={
									frontmatter.borderColor || "rgba(247,227,227, 0.5)"
								}
								title={frontmatter.title}
								showTitle={
									frontmatter.showTitle === null ? true : frontmatter.showTitle
								}
								isDraft={frontmatter.isDraft}
							/>
							<div dangerouslySetInnerHTML={{ __html: post.html }} />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				isDraft
				showTitle
				title
				publishDate
				postWidth
				borderColor
				borderWidth
			}
		}
	}
`
