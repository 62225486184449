import React from "react"
import moment from "moment"

export default props => (
	<div className="post--topmatter">		
		{props.isDraft && <p>DRAFT</p>}
		<p>
			<time
				className="post--date"
				dateTime={props.date}
				style={{ borderBottomColor: props.underlineColor }}
			>
				{moment(props.date).format("MMMM D, YYYY")}
			</time>
		</p>
		{props.showTitle && <h1 dangerouslySetInnerHTML={{__html: props.title}} />}
	</div>
)
